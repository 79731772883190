<template>
  <div class="page-login d-flex">
    <div class="right-main">
      <app-header />
      <div class="right-content">
        <v-container>
          <div class="d-flex f-center">
            <img width="160" src="/img/svg/login/logo.svg" alt="" />
          </div>
          <div class="fz-16 px-4 fw-b text">Connect your wallet</div>
          <login-choose />
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import LoginChoose from "@/views/login/components/login-choose.vue";
import Logo from "@/views/login/components/Logo";
import AppHeader from "@/views/login/components/AppHeader.vue";
import AppFooter from "@/views/login/components/AppFooter.vue";

export default {
  components: {
    LoginChoose,
    Logo,
    AppHeader,
    AppFooter,
  },
};
</script>

<style lang="scss">
.page-login {
  background-color: #fff;
  height: 100%;
  .login-left {
    flex-grow: 0;
    position: relative;
    width: 512px;
    // background-image: url("../assets/imgs/login/left-bg.png");
    background-size: 100% 100%;
    .left-img {
      width: 90%;
      position: absolute;
      right: 0;
      bottom: 40px;
    }
  }

  .right-main {
    height: calc(100% - 80px);
    flex-grow: 1;

    .right-content {
      max-width: 480px;
      height: 100%;
      display: flex;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin: 0 auto;
      padding: 0;
      .text {
        margin-top: 56px;
        color: #999;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .login-left {
    display: none;
  }
}
</style>
