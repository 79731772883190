<template>
  <div>
    <v-container class="d-flex align-center"> </v-container>
    <v-navigation-drawer v-model="showDrawer" right temporary fixed>
      <v-list>
        <template v-for="item in links">
          <v-list-group v-if="item.childs" :key="item.text" no-action>
            <template #activator>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="child in item.childs"
              :key="child.text"
              :href="child.href"
              :target="child.target"
            >
              <v-list-item-title>
                <span>
                  {{ child.text }}
                </span>
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :key="item.text"
            :href="item.href"
            :target="item.target"
          >
            <v-list-item-title>
              <span>
                {{ item.text }}
              </span>
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {
  mdiMenu,
  mdiGithub,
  mdiChevronDown,
  mdiChevronRight,
  mdiWallet,
} from "@mdi/js";
import Logo from "./Logo.vue";

export default {
  components: { Logo },
  data() {
    return {
      mdiMenu,
      mdiGithub,
      mdiChevronDown,
      mdiChevronRight,
      mdiWallet,
      showDrawer: false,
      links: [
        {
          text: "Blogs",
          href: "https://medium.com/4everland",
          target: "_blank",
        },
        {
          text: "Docs",
          href: "https://docs.4everland.org/",
          target: "_blank",
        },
        {
          text: "Community",
          href: "http://discord.gg/4everland",
          target: "_blank",
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.always-active {
  opacity: 1 !important;
}
.v-btn--active,
.nav-btn:hover {
  color: #2a7eed;
}
:v-deep .v-btn__content {
  opacity: 1 !important;
}
:v-deep .nav-btn .v-btn__content {
  opacity: 1 !important;
}
</style>
